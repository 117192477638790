import React, { Component } from "react";
import "./footer.css";

class Footer extends Component {
  state = {
    passSearchData: this.props.passSearchData,
  };

  onSearchUpdate = (event) => {
    event.persist();
    let value = event.target.value;
    this.state.passSearchData(value);
  };

  render() {
    return (
      <footer className="footer row noise bottom background-color-dark">
        <div className="col-lg-3 col-md-2"></div>
        <div className="col-lg-6 col-md-8 col-sm-12 col-12">
          <div className="row">
            <div className="col-lg-3 col-md-3"></div>
            <div className="footer-logo col-lg-2 col-md-2 d-lg-block d-md-block d-sm-none d-none"></div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-12">
              <h2 className="legal text-color-dark">
                {"Minns Sverige © " +
                  new Date().getFullYear() +
                  " All rights reserved"}
              </h2>
            </div>
            <div className="col-lg-3 col-md-3"></div>
          </div>
        </div>
        <div className="col-lg-3 col-md-2"></div>
      </footer>
    );
  }
}
export default Footer;
