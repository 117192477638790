import React, { Component } from "react";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";

import "components/header.css";

/// Main website header
class Header extends Component {
  textBox = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      textBoxValue: "",
    };
  }

  /// On search textbox value change
  onSearchUpdate = (event) => {
    event.persist();
    let value = event.target.value;
    this.setState({ textBoxValue: value });
    this.props.app.containerRef.current.state.searchword = value;
    this.props.app.containerRef.current.syncState();
    this.props.app.containerRef.current.createElements();
  };

  /// On Logo click ( go home )
  onLogoClick = (event) => {
    this.props.history.push("/");
  };

  // On search textbox click / focus
  onSearchClick = (event) => {
    this.props.app.containerRef.current.expandPartial();
  };

  // On search textbox de-focus
  onFocusOut = (event) => {
    this.props.app.containerRef.current.expandNone();
  };

  /// Reset searchbox
  resetTextBox = () => {
    this.textBox.current.value = "";
    this.state.textBoxValue = "";
    this.setState({ textBoxValue: this.state.textBoxValue });
    this.props.app.containerRef.current.state.searchword = this.state.textBoxValue;
  };

  /// Main render
  render() {
    return (
      <header className="header row noise background-color-dark">
        <div className="col-lg-3 col-md-2"></div>
        <div className="col-lg-6 col-md-8">
          <div className="row">
            <div
              className="header-logo col-lg-4 col-md-3 col-sm-3 col-3 d-lg-block d-md-block d-sm-block d-block"
              onClick={this.onLogoClick}
            ></div>
            <div className="search-form col-lg-4 col-md-6 col-sm-9 col-9">
              <form className="form-inline md-form mr-auto mb-4">
                <input
                  ref={this.textBox}
                  className="search-form-textbox form-control mr-sm-2"
                  type="text"
                  placeholder="Sök..."
                  aria-label="Sök..."
                  onChange={this.onSearchUpdate}
                  onClick={this.onSearchClick}
                  onBlur={this.onFocusOut}
                  value={this.state.textBoxValue}
                />
              </form>
            </div>
          </div>
          <div className="col-lg-4 col-md-3"></div>
        </div>
      </header>
    );
  }
}
export default PreProcessor(Header);

/// Component PreProcessor ( Passes various hooks )
function PreProcessor(Component) {
  return function WrappedComponent(props) {
    const { collection, image } = useParams();
    const history = useHistory();
    const match = useRouteMatch({
      path: "/:collection/:image",
      strict: true,
      sensitive: true,
    });
    return (
      <Component
        {...props}
        ref={props.reference}
        history={history}
        match={match}
      />
    );
  };
}
