import React, { Component } from "react";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import MetaTags from "react-meta-tags";

import "components/container.css";
import "bootstrap/dist/css/bootstrap.css";

import Element from "./element.jsx";

/// Container component
/// Shows list of image elements
class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      containerStyle: {
        maxHeight: "",
        minHeight: "",
        paddingTop: "10px",
        overflowY: "visible",
      },
      searchword: "",
      colleciton: "",
      content: [],
    };

    // Initialize
    this.syncState();
    this.createElements();
  }

  /// Expand component fully
  expandFull = () => {
    this.state.containerStyle = {
      maxHeight: "",
      minHeight: "100vh",
      paddingTop: "10px",
      overflowY: "visible",
    };
    this.setState({ containerStyle: this.state.containerStyle });
  };

  /// Expand compontent partially
  expandPartial = () => {
    if (this.props.match.params.collection && !this.props.match.params.image) {
      return; // Never partially expand if its mainscreen
    }

    this.state.containerStyle = {
      minHeight: "60px",
      maxHeight: "265px",
      paddingTop: "20px",
      overflowY: "scroll",
    };
    this.setState({ containerStyle: this.state.containerStyle });
  };

  /// Remove container from screen
  expandNone = () => {
    if (this.props.match.params.collection && !this.props.match.params.image) {
      return; // Never remove if its mainscreen
    }

    this.state.containerStyle = {
      maxHeight: "0px",
      minHeight: "",
      paddingTop: "0px",
      overflowY: "hidden",
    };
    this.setState({ containerStyle: this.state.containerStyle });
  };

  /// On props change
  componentWillReceiveProps(props) {
    if (this.props != props) {
      this.props = props;
      this.syncState();
      this.createElements();
    }
  }

  /// Statecheck
  syncState() {
    if (this.props.match) {
      if (this.props.match.params.image) {
        // Searhing in viewer
        if (this.state.searchword.length > 0) {
          this.expandPartial();
        } else {
          this.expandNone();
        }
      } else if (this.props.match.params.collection) {
        // Searchin in container ( normal search)
        this.expandFull();
      } else if (this.state.searchword.length > 0) {
        // Searching in homescreen
        this.expandPartial();
      } else {
        this.expandNone();
      }
    }
  }

  /// Create elements for lists
  async createElements() {
    await this.props.app.content.waitForContent();

    this.state.content = [];

    this.props.app.content.searchCollection(
      this.state.searchword.length == 0
        ? this.props.match.params.collection
        : undefined,
      this.state.searchword,
      (result) => {
        for (var i = 0; i < result.length; i++) {
          let item = result[i];
          let reference = React.createRef();
          let element = (
            <Element
              key={item.uniqueId}
              app={this.props.app}
              data={item}
              reference={reference}
              navigatorRef={this.props.navigatorRef}
            />
          );
          item.reference = reference;
          item.element = element;
          this.state.content.push(item);
        }
      }
    );
    this.setState({ content: this.state.content });
  }

  /// Main render
  displayContent() {
    /// Display "no results" if none
    if (this.state.content.length == 0) {
      return (
        <React.Fragment>
          <div className="col-lg-2 col-md-2"></div>
          <div
            style={this.state.containerStyle}
            className="col-lg-8 col-md-8 content element-search-container"
          >
            <h1 className="search-result-title text-color-base">
              Inga resultat
            </h1>
          </div>
          <div className="col-lg-2 col-md-2"></div>
        </React.Fragment>
      );
    }

    // Create categories
    let categories = [];
    let category_elements = [];
    let categoryExists = (name) => {
      for (var i = 0; i < categories.length; i++) {
        if (categories[i] == name) {
          return i;
        }
      }
      return -1;
    };
    for (var i = 0; i < this.state.content.length; i++) {
      let item = this.state.content[i];
      let id = categoryExists(item.category);
      if (id < 0) {
        // Add category
        categories.push(item.category);
        category_elements.push([item.element]);
      } else {
        // Add to existing category
        category_elements[id].push(item.element);
      }
    }

    this.final_content = [];
    // Build content
    for (var i = 0; i < categories.length; i++) {
      this.final_content.push(
        <div key={i} className="col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="row category-row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="row category-title-row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="row category-title">
                    <h1 className="text-color-light">
                      {categories[i][0].toUpperCase() + categories[i].slice(1)}
                    </h1>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="row category">{category_elements[i]}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        {/* Meta tags for link sharing format ( not working - need server side rendering )*/}
        <MetaTags>
          <title>{"Minns Sverige"}</title>
          <meta
            name="description"
            content={
              "Historiska bilder på Sverige tillsammans med noggrant återskapade jämförelsebilder."
            }
          />
          <meta property="og:title" content={"Minns Sverige"} />
          <meta
            property="og:image"
            content={`/media/lowres/blasieholmen_1_0.jpg`}
          />
        </MetaTags>

        <div className="col-lg-2 col-md-2"></div>
        <div
          style={this.state.containerStyle}
          className="col-lg-8 col-md-8 content element-search-container"
        >
          <div className="row">{this.final_content}</div>
        </div>
        <div className="col-lg-2 col-md-2"></div>
      </React.Fragment>
    );
  }

  /// Main render
  render() {
    return <div className="row">{this.displayContent()}</div>;
  }
}
export default PreProcessor(Container);

/// Component PreProcessor ( Passes various hooks )
function PreProcessor(Component) {
  return function WrappedComponent(props) {
    const { collection, image } = useParams();
    const history = useHistory();
    const match = useRouteMatch({
      path: "/:collection?/:image?",
      strict: true,
      sensitive: true,
    });
    return (
      <Component
        {...props}
        ref={props.reference}
        history={history}
        match={match}
      />
    );
  };
}
