import React, { Component } from "react";
import { Marker } from "@react-google-maps/api";

/// Google Maps clickable marker
class GoogleMapsMarker extends Component {
  /// Generic marker settings
  MapIcon = new window.google.maps.MarkerImage(
    "/resources/pin.svg",
    null,
    null,
    null,
    new window.google.maps.Size(32, 32)
  );

  /// Targeted marker settings
  MapIconTarget = new window.google.maps.MarkerImage(
    "/resources/pin-target.svg",
    null,
    null,
    null,
    new window.google.maps.Size(64, 64)
  );

  constructor(props) {
    super(props);

    this.state = {
      targeted: this.props.targeted,
    };
  }

  /// Set marker targeted
  setTargeted = (value) => {
    this.setState({ targeted: value });
  };

  /// On marker click event
  onMarkerClick = (event) => {
    this.props.map.props.viewer.props.history.push(
      "/" + this.props.data.collection + "/" + this.props.data.content
    );
    this.props.map.props.viewer.changeElement(
      this.props.data.collection,
      this.props.data.content,
      false
    );
  };

  /// Main render
  render() {
    return (
      <Marker
        icon={this.state.targeted ? this.MapIconTarget : this.MapIcon}
        position={{
          lat: this.props.data.lat,
          lng: this.props.data.lng,
        }}
        onClick={this.onMarkerClick}
        key={this.props.data.uniqueId}
      />
    );
  }
}
export default GoogleMapsMarker;
