import React, { Component } from "react";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";
import MetaTags from "react-meta-tags";

import "components/collections.css";
import "bootstrap/dist/css/bootstrap.css";

/// Collections component
/// Menu for selecting image collection
class Collections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pins: [],
      list: [],
      counts: [],
      containerStyle: {
        maxHeight: "100vh",
        minHeight: "",
        overflowY: "visible",
      },
    };

    // Initialize
    this.createList();
    this.expandFull();
  }

  /// Create Collections list and pins
  async createList() {
    this.state.list = [];
    this.state.pins = [];
    this.state.list.push(
      <div className="row collection-select-element-title">
        <h1 className="text-color-dark">{"Välj bildsamling:"}</h1>
      </div>
    );

    await this.props.app.content.waitForContent();
    for (var i = 0; i < this.props.app.content.data.length; i++) {
      let item = this.props.app.content.data[i];

      let elementReference = React.createRef();
      let pinReference = React.createRef();

      this.state.list.push(
        <div
          className="row collection-select-element background-color-dark"
          key={i}
          id={i}
          ref={elementReference}
          onMouseEnter={(e) => this.onHoverPin(e, item, pinReference, true)}
          onMouseLeave={(e) => this.onHoverPin(e, item, pinReference, false)}
          onClick={(e) => {
            this.props.history.push(item.collection);
          }}
        >
          <h1 className="text-color-base">
            <span className="collection-select-element-name">{item.name}</span>
            <span className="collection-select-element-count d-lg-block d-md-block d-sm-none d-none">
              {item.count + "st"}
            </span>
          </h1>
        </div>
      );

      let style = {
        left: `${item.position.x}%`,
        top: `${item.position.y}%`,
      };

      this.state.pins.push(
        <div className="collection-select-container" style={style}>
          <div
            className="collection-select"
            key={item.uniqueId}
            id={i}
            ref={pinReference}
            onClick={(e) => {
              this.props.history.push(item.collection);
            }}
          >
            <div className="select-circle"></div>
            <div className="select-text d-lg-block d-md-block d-sm-none d-none">
              <h1>{item.name}</h1>
            </div>
          </div>
        </div>
      );
    }
    this.forceUpdate();
  }

  /// On Pin hover event
  onHoverPin = (event, data, element, value) => {
    if (value) {
      element.current.classList.add("pin-hover");
    } else {
      element.current.classList.remove("pin-hover");
    }
  };

  /// Expand component
  expandFull = () => {
    this.setState({
      containerStyle: {
        maxHeight: "100vh",
        minHeight: "",
        overflowY: "visible",
      },
    });
  };

  /// Peak component
  expandPartial = () => {
    this.setState({
      containerStyle: {
        minHeight: "60px",
        maxHeight: "265px",
        overflowY: "scroll",
      },
    });
  };

  /// Remove component from screen
  expandNone = () => {
    this.setState({
      containerStyle: {
        maxHeight: "0px",
        minHeight: "",
        overflowY: "hidden",
      },
    });
  };

  /// Render
  render() {
    return (
      <React.Fragment>
        {/* Meta tags for link sharing format ( not working - need server side rendering )*/}
        <MetaTags>
          <title>{"Minns Sverige"}</title>
          <meta
            name="description"
            content={
              "Historiska bilder på Sverige tillsammans med noggrant återskapade jämförelsebilder."
            }
          />
          <meta property="og:title" content={"Minns Sverige"} />
          <meta
            property="og:image"
            content={`/media/lowres/blasieholmen_1_0.jpg`}
          />
        </MetaTags>

        <div
          style={this.state.containerStyle}
          className="container col-lg-12 col-md-12"
        >
          <div className="row container-row">
            <div className="col-lg-2 col-md-2 d-lg-block d-md-block d-sm-none d-none"></div>
            <div className="map-container col-lg-4 col-md-4 col-sm-6 col-6 d-lg-block d-md-block d-sm-none d-none">
              <div className="map-wrapper">
                <div className="map-image">
                  <img className="map" src="/resources/sverige.svg"></img>
                </div>
                <div className="pins-wrapper">{this.state.pins}</div>
              </div>
            </div>
            <div className="list-container col-lg-5 col-md-5 col-sm-6 col-6">
              <div className="row">
                <div className="list-floater col-lg-12 col-md-12 col-sm-12 col-12">
                  {this.state.list}
                </div>
              </div>
            </div>
            <div className="col-lg-1 col-md-1 d-lg-block d-md-block d-sm-none d-none"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default PreProcessor(Collections);

/// Component PreProcessor ( Passes various hooks )
function PreProcessor(Component) {
  return function WrappedComponent(props) {
    const { collection, image } = useParams();
    const history = useHistory();
    const match = useRouteMatch({
      path: "/:collection/:image?",
      strict: true,
      sensitive: true,
    });
    return (
      <Component
        {...props}
        ref={props.reference}
        history={history}
        match={match}
      />
    );
  };
}
