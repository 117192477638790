import React, { Component } from "react";
import { useParams, useHistory, useRouteMatch } from "react-router-dom";

import { GoogleMap } from "@react-google-maps/api";
import MapStyle from "data/map-style.json";
import GoogleMapsMarker from "components/googlemapsmarker.jsx";

/// Google Maps component
class Map extends Component {
  /// Map style
  MapOptions = {
    styles: MapStyle,
    disableDefaultUI: true,
    zoomControl: true,
  };

  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];

    this.state = {
      markers: [],
      startPosition: {
        lat: 0.0,
        lng: 0.0,
      },
    };

    this.initialize();
  }

  /// On props change
  componentWillReceiveProps(props) {
    if (this.props.match.params.collection != props.match.params.collection) {
      this.props = props;
      this.initialize();
    }
  }

  /// Initialize map center
  async initialize() {
    await this.props.content.waitForContent();

    this.props.content.getElement(
      this.props.match.params.collection,
      this.props.match.params.image,
      (element) => {
        this.state.startPosition = { lat: element.lat, lng: element.lng };
        this.setState({ startPosition: this.state.startPosition });
      }
    );
    this.createMarkers();
  }

  /// Pan to location
  setPosition = ({ lat, lng }) => {
    if (lat && lng) {
      this.mapRef.current.panTo({ lat, lng });
    }
  };

  /// Create markers
  async createMarkers() {
    await this.props.content.waitForContent();

    this.state.markers = [];
    this.markers = [];

    this.props.content.getCollection(
      this.props.match.params.collection,
      (collection) => {
        for (var i = 0; i < collection.length; i++) {
          let item = collection[i];
          let ref = React.createRef();
          this.markers.push(ref);
          this.state.markers.push(
            <GoogleMapsMarker
              key={item.uniqueId}
              targeted={this.props.match.params.image == item.content}
              data={item}
              map={this}
              ref={ref}
            />
          );
        }
      }
    );
    this.setState({ markers: this.state.markers });
  }

  /// Reset all pins
  targetMarker(image) {
    for (var i = 0; i < this.markers.length; i++) {
      let marker = this.markers[i];
      if (marker.current.props.data.content == image) {
        marker.current.setTargeted(true);
        this.setPosition({
          lat: marker.current.props.data.lat,
          lng: marker.current.props.data.lng,
        });
      } else {
        marker.current.setTargeted(false);
      }
    }
  }

  /// Main render
  render() {
    return (
      <GoogleMap
        mapContainerStyle={{
          height: `20vw`,
          minHeight: `200px`,
          width: `100%`,
        }}
        zoom={14}
        center={this.state.startPosition}
        options={this.MapOptions}
        onLoad={(ref) => {
          this.mapRef.current = ref;
        }}
      >
        {this.state.markers}
      </GoogleMap>
    );
  }
}
export default PreProcessor(Map);

/// Component PreProcessor ( Passes various hooks )
function PreProcessor(Component) {
  return function WrappedComponent(props) {
    const { collection, image } = useParams();
    const history = useHistory();
    const match = useRouteMatch({
      path: "/:collection/:image",
      strict: true,
      sensitive: true,
    });
    return (
      <Component
        {...props}
        ref={props.reference}
        history={history}
        match={match}
      />
    );
  };
}
