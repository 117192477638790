const { Component } = require("react");

/// Main content manager
/// Used as psuedo-database
class ContentManager extends Component {
  constructor(props) {
    super(props);

    this.data = [];
    this.loaded = false;
    this.path = "collections.json";

    this.loading = this.initialize();
  }

  async initialize() {
    await this.loadData(this.path);
  }

  /// Query entire database
  async searchDatabase(searchword, onFinish) {
    let result = [];

    for (var i = 0; i < this.data.length; i++) {
      let collection = this.data[i];
      for (var j = 0; j < collection.items.length; j++) {
        let item = collection.items[j];
        if (this.isMatched(item, searchword)) {
          // Add to result
          result.push(item);
        }
      }
    }

    if (onFinish) {
      onFinish(result);
    }
    return result;
  }

  /// Query specific collection
  async searchCollection(collection, searchword, onFinish) {
    let result = [];

    for (var i = 0; i < this.data.length; i++) {
      if (this.data[i].collection == collection || collection == undefined) {
        let collection = this.data[i];
        for (var j = 0; j < collection.items.length; j++) {
          let item = collection.items[j];
          if (this.isMatched(item, searchword)) {
            // Add to result
            result.push(item);
          }
        }
      }
    }

    if (onFinish) {
      onFinish(result);
    }
    return result;
  }

  /// Match searchword
  isMatched(item, searchword) {
    let regex = `^.*${searchword.toLowerCase()}.*$`;
    if (searchword != "") {
      let match = false;

      // Match keywords
      for (var j = 0; j < item.keywords.length; j++) {
        let string = item.keywords[j].toLowerCase();
        if (string.match(regex)) {
          match = true;
        }
      }

      // Match collection
      if (item.collection.toLowerCase().match(regex)) {
        match = true;
      }

      // Match name
      if (item.name.toLowerCase().match(regex)) {
        match = true;
      }

      // Match year
      if (searchword.length < 4) {
        if (!match) {
          regex = `^.*${searchword}.*$`;
          match = item.year.toString().match(regex);
        }
      } else {
        // Match within 4 year span
        if (!isNaN(searchword)) {
          let year = parseInt(searchword);
          if (year <= item.year + 2 && year >= item.year - 2) {
            match = true;
          }
        }
      }
      return match;
    }
    return true;
  }

  /// Find image in collection
  async getElement(collection, image, onFinish) {
    for (var i = 0; i < this.data.length; i++) {
      if (this.data[i]["collection"] == collection) {
        for (var j = 0; j < this.data[i]["items"].length; j++) {
          let item = this.data[i]["items"][j];
          if (item.content == image) {
            onFinish(item);
            return;
          }
        }
      }
    }
    onFinish(undefined);
  }

  /// Find image in collection by id
  async getElementById(uniqueId, onFinish) {
    for (var i = 0; i < this.data.length; i++) {
      for (var j = 0; j < this.data[i]["items"].length; j++) {
        let item = this.data[i]["items"][j];
        if (item.uniqueId == uniqueId) {
          onFinish(item);
          return;
        }
      }
    }
    onFinish(undefined);
  }

  /// Get neighbouring items
  async getElementNeighbours(collection, image) {
    let leftNeighbour = undefined;
    let rightNeighbour = undefined;

    for (var i = 0; i < this.data.length; i++) {
      if (this.data[i]["collection"] == collection) {
        for (var j = 0; j < this.data[i]["items"].length; j++) {
          let item = this.data[i]["items"][j];
          if (item.content == image) {
            leftNeighbour = this.data[i]["items"][
              this.wrapIndex(this.data[i]["items"].length, j - 1)
            ];
            rightNeighbour = this.data[i]["items"][
              this.wrapIndex(this.data[i]["items"].length, j + 1)
            ];
            break;
          }
        }
      }
    }
    return { left: leftNeighbour, right: rightNeighbour };
  }

  /// Returns collection array
  async getCollection(collection, onFinish) {
    for (var i = 0; i < this.data.length; i++) {
      if (this.data[i]["collection"] == collection) {
        onFinish(this.data[i]["items"]);
        return;
      }
    }
    onFinish(undefined);
  }

  /// Returns all data
  async getData() {
    return this.data;
  }

  /// Create item data
  createItem(item) {
    return {
      id: item.id,
      uniqueId: item.uniqueId,
      content: item.content || "",
      collection: item.collection || "",
      category: item.category || "",

      name: item.name || "Name",
      year: item.early_yr || 0,
      created: item.late_yr || 0,
      description: item.description || "Description",
      address: item.address || "Adress",
      lng: item.coordinates["lat"],
      lat: item.coordinates["long"],
      alert: item.alert || "",
      note: item.note || "",

      link: item.link || "",
      license: item.license || "",
      license_holder: item.license_holder || "",
      license_link: item.license_link || "",
      license_source: item.license_source || "",
      photographer: item.photographer || "",

      keywords: item.keywords || [],
    };
  }

  async waitForContent() {
    await this.loading;
  }

  /// Load and format all data
  async loadData(path) {
    if (!this.loaded) {
      this.data = [];
      let raw = await import(`data/${path}`);

      for (var i = 0; i < raw["items"].length; i++) {
        let collection = raw["items"][i];
        if (collection["visible"]) {
          let cdata = await import(`data/${collection["content"]}.json`);
          let count = 0;
          for (var j = 0; j < cdata["items"].length; j++) {
            if (cdata["items"][j]["visible"]) {
              cdata["items"][j].uniqueId = (i + 1) * 100 + j;
              cdata["items"][j].collection = collection["content"];
              // Reformat data
              cdata["items"][j] = this.createItem(cdata["items"][j]);
              count++;
            } else {
              cdata["items"].splice(j, 1);
              j--;
            }
          }
          cdata.count = count;
          cdata.position = {
            x: collection["position"]["x"],
            y: collection["position"]["y"],
          };
          this.data.push(cdata);
        }
      }
      this.loaded = true;
    }
  }
  /*
    // Formatting
    {
        "name": Collection name,
        "collection" Collection data name,
        "count": Collection size,
        "position":
        {
          "x": Collection position x,
          "y": Collection position y
        },
        "items": Array of images
    }
  */

  /// Wrap id
  wrapIndex(length, index) {
    if (index < 0) {
      return length - 1;
    }
    if (index >= length) {
      return 0;
    }
    return index;
  }
}
export default ContentManager;
